import { createBrowserRouter } from "react-router-dom";
import AdminBookingCards from "./screens/admin-booking-cards";
import AdminDriverView from "./screens/admin-driver-view";
import AdminLocationView from "./screens/admin-location-view";
import AdminLocations from "./screens/admin-locations";
import AvailableCubbis from "./screens/available-cubbis";
import AdminDoorDash from "./screens/admin-doordash";
import AdminPickups from "./screens/admin-pickups";
import AdminDoorDashById from "./screens/admin-doordash-by-id";
import AdminPickupById from "./screens/admin-pickup-by-id";

const ADMIN_ROUTER = createBrowserRouter([
  {
    path: "/",
    element: <AdminDriverView />,
  },
  {
    path: "/locations",
    element: <AdminLocations />,
  },
  {
    path: "/locations/:id",
    element: <AdminLocationView />,
  },
  {
    path: "/locations/:id/booking-cards",
    element: <AdminBookingCards />,
  },
  {
    path: "/booking-reassign",
    element: <AvailableCubbis />,
  },
  {
    path: "/doordash",
    element: <AdminDoorDash />,
  },
  {
    path: "/doordash/:id",
    element: <AdminDoorDashById />,
  },
  {
    path: "/pickups",
    element: <AdminPickups />,
  },
  {
    path: "/pickups/:id",
    element: <AdminPickupById />,
  },
]);

export default ADMIN_ROUTER;
