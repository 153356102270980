import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useContext } from "react";
import { classNames } from "../utils/classNames";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../providers/auth.provider";

interface Tab {
  name: string;
  href: string;
}

const tabs: Tab[] = [
  { name: "Drivers", href: "/" },
  { name: "Locations", href: "/locations" },
  { name: "DoorDash", href: "/doordash" },
  { name: "Pickups", href: "/pickups" },
];

export default function AdminNavigation() {
  const navigate = useNavigate();
  const { setAuthState } = useContext(AuthContext);
  const userNavigation = [
    {
      name: "Logout",
      action: () => {
        navigate("/");
        localStorage.clear();
        setAuthState({ setAuthState, token: null, user: null });
      },
    },
  ];

  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="border-b border-gray-200 bg-black">
          {({ open }) => (
            <>
              <div>
                <div className="flex h-16 justify-between">
                  <div className="flex">
                    <div className="-mb-px flex" aria-label="Tabs">
                      {tabs.map((item) => {
                        const isActive =
                          item.href === "/"
                            ? window.location.pathname === "/"
                            : window.location.pathname.startsWith(item.href);

                        return (
                          <div
                            onClick={() => {
                              navigate(item.href);
                            }}
                            key={item.name}
                            className={classNames(
                              isActive
                                ? "border-indigo-200 text-white border-b-4 font-medium"
                                : "text-unselected hover:text-gray-700",
                              "whitespace-nowrap py-6 text-md font-medium w-30 text-center cursor-pointer"
                            )}
                            aria-current={isActive ? "page" : undefined}
                          >
                            {item.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="sm:ml-6 flex items-center">
                    <Menu as="div" className="relative mr-3">
                      <div>
                        <Menu.Button className="flex max-w-xs items-center rounded-full text-sm">
                          {open ? (
                            <XMarkIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <Bars3Icon
                              className="block h-8 w-8 text-white"
                              aria-hidden="true"
                            />
                          )}
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <div
                                  onClick={item.action}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                  )}
                                >
                                  {item.name}
                                </div>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
}
