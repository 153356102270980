import { useMemo, useState } from "react";
import DashboardProvider from "../providers/dashboard.provider";
import { useGroupOrders } from "../services/team-order.service";
import { GroupOrder } from "../types";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import Spinner from "../components/loading";
import CityDropdownFilter from "../components/city-dropdown-filter";
import { useNavigate } from "react-router-dom";

const deliveryType = "doordash";

export default function AdminDoorDash() {
  const [selectedCity, setSelectedCity] = useState("Calgary");
  const today = useMemo(() => new Date().toISOString(), []);
  const navigate = useNavigate();
  const { orders, loading } = useGroupOrders(selectedCity, today, deliveryType);

  const cities = ["Calgary", "Saskatoon"];

  function handleNavigateToOrder(id: number) {
    navigate(`/doordash/${id}`);
  }

  return (
    <DashboardProvider>
      <div className="p-4">
        <div className="mb-4">
          <CityDropdownFilter
            items={cities}
            title={selectedCity}
            functionToSend={setSelectedCity}
            changed={true}
            width="w-38"
            widthOfDropdown="w-48"
            height="h-auto"
          />
        </div>
        {loading && <Spinner />}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {orders?.map((order: GroupOrder) => (
            <div
              onClick={() => handleNavigateToOrder(order.id)}
              key={order.id}
              className="bg-white shadow-md rounded-lg p-4 cursor-pointer"
            >
              <div className="flex items-center">
                <div className="flex-grow">
                  <h2 className="text-[16px] font-semibold mb-2">
                    {order.fridge.name}
                  </h2>
                  <div className="flex flex-col space-y-4">
                    <p className="text-sm text-gray-600">
                      DoorDash Number: {order.delivery.id}
                    </p>
                    <p className="text-sm text-gray-600">
                      Cubbi Order #: {order.id}
                    </p>
                    <p className="text-sm text-gray-600">
                      Delivery time:{" "}
                      {new Date(
                        order.delivery.startDatetime
                      ).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}{" "}
                      -{" "}
                      {(() => {
                        const endTime = new Date(order.delivery.startDatetime);
                        endTime.setMinutes(endTime.getMinutes() + 15);
                        return endTime.toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        });
                      })()}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-center ml-4">
                  <ChevronRightIcon className="h-5 w-5 text-gray-500" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </DashboardProvider>
  );
}
