import { createBrowserRouter } from "react-router-dom";

import DriverHome from "./screens/driver-home";
import AdminLocationView from "./screens/admin-location-view";
import AdminBookingCards from "./screens/admin-booking-cards";
import AvailableCubbis from "./screens/available-cubbis";

const DRIVER_ROUTER = createBrowserRouter([
  {
    path: "/",
    element: <DriverHome />,
    loader: () => {
      console.log("loading driverr");
      return null;
    },
  },
  {
    path: "/locations/:id",
    element: <AdminLocationView />,
  },
  {
    path: "/locations/:id/booking-cards",
    element: <AdminBookingCards />,
  },
  {
    path: "/booking-reassign",
    element: <AvailableCubbis />,
  },
]);

export default DRIVER_ROUTER;
