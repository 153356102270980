import { gql, useQuery } from "@apollo/client";
import { DriverAppOrder } from "../types";
import getNodeDataFromEdges from "../utils/get-node-data-from-edges";

export const ADMIN_ORDERS = gql`
  query driverAppOrders(
    $fridgeList: [Int]
    $endTime: DateTime
    $startTime: DateTime
    $cubbiFridgeId: Float
  ) {
    driverAppOrders(fridgeList: $fridgeList) {
      id
      createdAt
      bookings(
        startAt_Lt: $startTime
        endAt_Gt: $endTime
        cancelledAt_Isnull: true
        cantDeliver: false
        cubbi_Fridge_Id: $cubbiFridgeId
      ) {
        edges {
          node {
            id
            deliveredAt
            cancelledAt
            completed
            startAt
            endAt
            orderId
            cubbi {
              id
              position
              use
              communal
              groupOrder
            }
            bookingItems {
              name
              quantity
            }
            owner {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export function useDriverAppOrders(
  fridgeList: number | undefined,
  startDate: string,
  endDate: string
) {
  const { data, loading, error, refetch } = useQuery<{
    driverAppOrders: DriverAppOrder[];
  }>(ADMIN_ORDERS, {
    skip: fridgeList === undefined,
    variables: {
      fridgeList: [fridgeList],
      startTime: startDate,
      endTime: endDate,
      cubbiFridgeId: fridgeList,
    },
    fetchPolicy: "network-only",
  });

  const bookings = data?.driverAppOrders?.flatMap((e) => e.bookings);
  const bookingsFromEdges = bookings?.flatMap(
    (e) => e.edges && getNodeDataFromEdges(e.edges)
  );

  return { bookings: bookingsFromEdges ?? [], loading, error, refetch };
}

export const UPDATE_BOOKING = gql`
  mutation updateBooking($id: Int!, $input: BookingInput!) {
    updateBooking(id: $id, input: $input) {
      ok
    }
  }
`;

export const MARK_ALL_AS_DELIVERED = gql`
  mutation updateFridge($id: Int!, $complete: DateTime) {
    updateFridge(id: $id, input: { complete: $complete }) {
      ok
    }
  }
`;
